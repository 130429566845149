@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");

$container: #e0e2e8;
$topbg: #ffcc05;
$bottombg: #fff;
$font: "Open Sans", sans-serif;
$grey: #6c6c6c;
.travel {
p,
h1 {
	margin: 0;
	padding: 0;
	font-family: $font;
    font-size: 35px;
    line-height: 1;
    font-weight: 700;
}
}
.travel {
.container {
	background: $container;
	position: relative;
	width: 100%;
	height: 100vh;
	.ticket {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.basic {
		display: none;
	}
}
}
.travel {
.airline {
	display: block;
	height: 575px;
	width: 270px;
	border-radius: 25px;
	z-index: 3;
	.top {
		height: 220px;
		background: $topbg;
		border-top-right-radius: 25px;
		border-top-left-radius: 25px;
		h1 {
			text-transform: uppercase;
			font-size: 12px;
			letter-spacing: 2;
			text-align: center;
			position: absolute;
			top: 30px;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	.bottom {
		height: 355px;
		background: $bottombg;
		border-bottom-right-radius: 25px;
		border-bottom-left-radius: 25px;
	}
}
}
.travel {
.top {
	.big {
		position: absolute;
		top: 100px;
		font-size: 65px;
		font-weight: 700;
		line-height: 0.8;
		.from {
			color: $topbg;
			text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
		}
		.to {
			position: absolute;
			left: 32px;
			font-size: 35px;
			display: flex;
			flex-direction: row;
			align-items: center;
			i {
				margin-top: 5px;
				margin-right: 10px;
				font-size: 15px;
			}
		}
	}
	&--side {
		position: absolute;
		right: 35px;
		top: 110px;
		text-align: right;
		i {
			font-size: 25px;
			margin-bottom: 18px;
		}
		p {
			font-size: 10px;
			font-weight: 700;
		}
		p + p {
			margin-bottom: 8px;
		}
	}
}
}
.travel {
.bottom {
	p {
		display: flex;
		flex-direction: column;
		font-size: 13px;
		font-weight: 700;
		span {
			font-weight: 400;
			font-size: 11px;
			color: $grey;
		}
	}
	.column {
		margin: 0 auto;
		width: 80%;
		padding: 2rem 0;
	}
	.row {
        //width: 50%;
		display: flex;
		justify-content: space-between;
		&--right {
			text-align: right;
		}
		&--center {
			text-align: center;
		}
		&-2 {
			margin: 30px 0 60px 0;
			position: relative;
			&::after {
				content: "";
				position: absolute;
				width: 100%;
				bottom: -30px;
				left: 0;
				background: #000;
				height: 1px;
			}
		}
	}
    .row>*{
        width: unset;
        --bs-gutter-x:20px;
    }
}
}
.travel {
.bottom {
	.bar--code {
		height: 50px;
		width: 80%;
		margin: 0 auto;
		position: relative;
		&::after {
			content: "";
			position: absolute;
			width: 6px;
			height: 100%;
			background: #000;
			top: 0;
			left: 0;
			box-shadow: 10px 0 #000, 30px 0 #000, 40px 0 #000, 67px 0 #000, 90px 0 #000,
				100px 0 #000, 180px 0 #000, 165px 0 #000, 200px 0 #000, 210px 0 #000,
				135px 0 #000, 120px 0 #000;
		}
		&::before {
			content: "";
			position: absolute;
			width: 3px;
			height: 100%;
			background: #000;
			top: 0;
			left: 11px;
			box-shadow: 12px 0 #000, -4px 0 #000, 45px 0 #000, 65px 0 #000, 72px 0 #000,
				78px 0 #000, 97px 0 #000, 150px 0 #000, 165px 0 #000, 180px 0 #000,
				135px 0 #000, 120px 0 #000;
		}
	}
}
}
